import React from 'react'
const Loader = () => {
  return (
        <div className="preLoaderOuter">
        <span class="loader"></span>
      </div>
  )
}

export default Loader;
