import React from 'react'
import Rout from './Utils/Rout'
import './App.css';

const App = () => {
  return (
   <Rout/>
  )
}

export default App